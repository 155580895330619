exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-1-js": () => import("./../../../src/pages/about-1.js" /* webpackChunkName: "component---src-pages-about-1-js" */),
  "component---src-pages-about-2-js": () => import("./../../../src/pages/about-2.js" /* webpackChunkName: "component---src-pages-about-2-js" */),
  "component---src-pages-about-3-js": () => import("./../../../src/pages/about-3.js" /* webpackChunkName: "component---src-pages-about-3-js" */),
  "component---src-pages-author-js": () => import("./../../../src/pages/author.js" /* webpackChunkName: "component---src-pages-author-js" */),
  "component---src-pages-bicak-izi-acilim-kutu-tasarimi-js": () => import("./../../../src/pages/bicak-izi-acilim-kutu-tasarimi.js" /* webpackChunkName: "component---src-pages-bicak-izi-acilim-kutu-tasarimi-js" */),
  "component---src-pages-bigdata-analytics-js": () => import("./../../../src/pages/bigdata-analytics.js" /* webpackChunkName: "component---src-pages-bigdata-analytics-js" */),
  "component---src-pages-blog-1-js": () => import("./../../../src/pages/blog-1.js" /* webpackChunkName: "component---src-pages-blog-1-js" */),
  "component---src-pages-blog-2-js": () => import("./../../../src/pages/blog-2.js" /* webpackChunkName: "component---src-pages-blog-2-js" */),
  "component---src-pages-blog-3-js": () => import("./../../../src/pages/blog-3.js" /* webpackChunkName: "component---src-pages-blog-3-js" */),
  "component---src-pages-blog-4-js": () => import("./../../../src/pages/blog-4.js" /* webpackChunkName: "component---src-pages-blog-4-js" */),
  "component---src-pages-blog-5-js": () => import("./../../../src/pages/blog-5.js" /* webpackChunkName: "component---src-pages-blog-5-js" */),
  "component---src-pages-blog-6-js": () => import("./../../../src/pages/blog-6.js" /* webpackChunkName: "component---src-pages-blog-6-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-digital-agency-js": () => import("./../../../src/pages/digital-agency.js" /* webpackChunkName: "component---src-pages-digital-agency-js" */),
  "component---src-pages-digital-agency-portfolio-js": () => import("./../../../src/pages/digital-agency-portfolio.js" /* webpackChunkName: "component---src-pages-digital-agency-portfolio-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-feature-details-js": () => import("./../../../src/pages/feature-details.js" /* webpackChunkName: "component---src-pages-feature-details-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-gizlilik-politikasi-js": () => import("./../../../src/pages/gizlilik-politikasi.js" /* webpackChunkName: "component---src-pages-gizlilik-politikasi-js" */),
  "component---src-pages-hosting-js": () => import("./../../../src/pages/hosting.js" /* webpackChunkName: "component---src-pages-hosting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iot-js": () => import("./../../../src/pages/iot.js" /* webpackChunkName: "component---src-pages-iot-js" */),
  "component---src-pages-it-startup-2-js": () => import("./../../../src/pages/it-startup-2.js" /* webpackChunkName: "component---src-pages-it-startup-2-js" */),
  "component---src-pages-it-startup-js": () => import("./../../../src/pages/it-startup.js" /* webpackChunkName: "component---src-pages-it-startup-js" */),
  "component---src-pages-konya-yazilim-firmalari-yeni-haberler-js": () => import("./../../../src/pages/konya-yazilim-firmalari-yeni-haberler.js" /* webpackChunkName: "component---src-pages-konya-yazilim-firmalari-yeni-haberler-js" */),
  "component---src-pages-konya-yazilim-firmasi-calisma-sekilleri-js": () => import("./../../../src/pages/konya-yazilim-firmasi-calisma-sekilleri.js" /* webpackChunkName: "component---src-pages-konya-yazilim-firmasi-calisma-sekilleri-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-machine-learning-2-js": () => import("./../../../src/pages/machine-learning-2.js" /* webpackChunkName: "component---src-pages-machine-learning-2-js" */),
  "component---src-pages-machine-learning-js": () => import("./../../../src/pages/machine-learning.js" /* webpackChunkName: "component---src-pages-machine-learning-js" */),
  "component---src-pages-misyon-vizyon-js": () => import("./../../../src/pages/misyon-vizyon.js" /* webpackChunkName: "component---src-pages-misyon-vizyon-js" */),
  "component---src-pages-mobil-uygulama-siparis-fatura-js": () => import("./../../../src/pages/mobil-uygulama-siparis-fatura.js" /* webpackChunkName: "component---src-pages-mobil-uygulama-siparis-fatura-js" */),
  "component---src-pages-ozel-yazilim-firmasi-js": () => import("./../../../src/pages/ozel-yazilim-firmasi.js" /* webpackChunkName: "component---src-pages-ozel-yazilim-firmasi-js" */),
  "component---src-pages-ozel-yazilimlar-js": () => import("./../../../src/pages/ozel-yazilimlar.js" /* webpackChunkName: "component---src-pages-ozel-yazilimlar-js" */),
  "component---src-pages-pawi-veteriner-asi-takip-yazilimi-js": () => import("./../../../src/pages/pawi-veteriner-asi-takip-yazilimi.js" /* webpackChunkName: "component---src-pages-pawi-veteriner-asi-takip-yazilimi-js" */),
  "component---src-pages-pc-repair-js": () => import("./../../../src/pages/pc-repair.js" /* webpackChunkName: "component---src-pages-pc-repair-js" */),
  "component---src-pages-pera-pdks-yazilimlari-otomatik-vardiya-secim-js": () => import("./../../../src/pages/pera-pdks-yazilimlari-otomatik-vardiya-secim.js" /* webpackChunkName: "component---src-pages-pera-pdks-yazilimlari-otomatik-vardiya-secim-js" */),
  "component---src-pages-pixel-yazilim-hakkinda-js": () => import("./../../../src/pages/pixel-yazilim-hakkinda.js" /* webpackChunkName: "component---src-pages-pixel-yazilim-hakkinda-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-pro-steel-celik-konstruksiyon-uretim-takip-yazilimi-js": () => import("./../../../src/pages/pro-steel-celik-konstruksiyon-uretim-takip-yazilimi.js" /* webpackChunkName: "component---src-pages-pro-steel-celik-konstruksiyon-uretim-takip-yazilimi-js" */),
  "component---src-pages-product-details-js": () => import("./../../../src/pages/product-details.js" /* webpackChunkName: "component---src-pages-product-details-js" */),
  "component---src-pages-proje-talebi-js": () => import("./../../../src/pages/proje-talebi.js" /* webpackChunkName: "component---src-pages-proje-talebi-js" */),
  "component---src-pages-project-details-js": () => import("./../../../src/pages/project-details.js" /* webpackChunkName: "component---src-pages-project-details-js" */),
  "component---src-pages-projects-1-js": () => import("./../../../src/pages/projects-1.js" /* webpackChunkName: "component---src-pages-projects-1-js" */),
  "component---src-pages-projects-2-js": () => import("./../../../src/pages/projects-2.js" /* webpackChunkName: "component---src-pages-projects-2-js" */),
  "component---src-pages-referanslarimiz-js": () => import("./../../../src/pages/referanslarimiz.js" /* webpackChunkName: "component---src-pages-referanslarimiz-js" */),
  "component---src-pages-service-details-js": () => import("./../../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-services-1-js": () => import("./../../../src/pages/services-1.js" /* webpackChunkName: "component---src-pages-services-1-js" */),
  "component---src-pages-services-2-js": () => import("./../../../src/pages/services-2.js" /* webpackChunkName: "component---src-pages-services-2-js" */),
  "component---src-pages-services-3-js": () => import("./../../../src/pages/services-3.js" /* webpackChunkName: "component---src-pages-services-3-js" */),
  "component---src-pages-services-4-js": () => import("./../../../src/pages/services-4.js" /* webpackChunkName: "component---src-pages-services-4-js" */),
  "component---src-pages-services-5-js": () => import("./../../../src/pages/services-5.js" /* webpackChunkName: "component---src-pages-services-5-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-start-up-arsiv-js": () => import("./../../../src/pages/start-up-arsiv.js" /* webpackChunkName: "component---src-pages-start-up-arsiv-js" */),
  "component---src-pages-start-up-yazilim-ortakligi-js": () => import("./../../../src/pages/start-up-yazilim-ortakligi.js" /* webpackChunkName: "component---src-pages-start-up-yazilim-ortakligi-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-yazilim-ekibi-js": () => import("./../../../src/pages/yazilim-ekibi.js" /* webpackChunkName: "component---src-pages-yazilim-ekibi-js" */)
}

